<template>
  <div class="lottery">
    <n-header></n-header>
    <nn-menu-bar class="menu-bar" :fixed="is_fixed"></nn-menu-bar>
    <div :class="{main: is_fixed}" style="backgroundColor: #f1f2f3;">
      
      <router-view></router-view>
    </div>
    <n-footer></n-footer>
  </div>
</template>

<script>
import Header from '@components/pc/Header'
import Footer from '@components/pc/Footer'
import MenuBar from '@components/pc/MenuBar'

export default {
  name: 'Lottery',
  components: {
    [Header.name]: Header,
    [Footer.name]: Footer,
    [MenuBar.name]: MenuBar
  },
  computed: {
    // path () {
    //   return this.$route.path === 'lottery'
    // }
  },
  data () {
    return {
      is_fixed: false,
      over_1280: false
    }
  },
  methods: {
    is_over () {
      const w = document.documentElement.clientWidth || document.body.clientWidth
      this.over_1280 = w > 1280 ? false : true
    }
  },
  mounted () {
    this.is_over()
    let t = null
    const self = this
    window.onresize = () => {
      self.is_over()
    }
    window.onscroll = () => {
      if (t) return
      const top = document.documentElement.scrollTop || document.body.scrollTop
      self.is_fixed = self.over_1280 ? false : top >= 110
      t = setTimeout(() => {
        const top = document.documentElement.scrollTop || document.body.scrollTop
        self.is_fixed = self.over_1280 ? false : top >= 110
        clearTimeout(t)
        t = null
      }, 80)
    }
  },
  destroyed () {
    window.onresize = null
    window.onscroll = null
  }
}
</script>

<style lang="scss" scoped>
@import "~@scss/pc/variables";
@import "~@scss/pc/mixin";
.menu-bar {
  top: $header-height;
}

.main {
  padding-top: $menu-bar-height * 2;
}
</style>
