<template>
  <div class="menu-bar" :style="{ position: fixed ? 'fixed' : 'static', top: fixed ? '0px' : 'none' }">
    <ul class="menus container">
      <div class="logo_box">
        <img src="@assets/pc/logo.png" class="logo block" />
      </div>
      <router-link style="margin-right: 30px;" tag="li" to="/lottery"
        :class="{ b_red: path == '/lottery' }"><span>彩票首页</span></router-link>
      <li @mouseenter="mouseenter" @mouseleave="mouseleave" style="position: relative;"
        :class="{ b_red: /\/code/.test(path) }">
        <span style="margin-right: 12px;">彩票<img class="icon" src="@assets/pc/icon_lottery_home_down.png" /></span>
        <transition name="fade">
          <div v-if="delayMenuT === null">
          <div class="lotterys" v-show="showLotterys">
            <div class="container content">
              <!-- <div class="links">
                <ul>
                  <router-link tag="li" :to="`/lottery/code/${lottery.code}/${lottery.id}`" v-for="lottery in lotteries" :key="lottery.id"><span>{{ lottery.name }}</span></router-link>
                </ul>
              </div> -->
              <div class="box" v-for="(lotteryGroup, i) in lotteries" :key="i">
                <div class="menu_ul">
                  <div class="menu_li" v-for="lottery in lotteryGroup" :key="lottery.id">
                    <span @click.stop="menu(lottery.code, lottery.id)"
                      :style="{ color: path.indexOf(`code/${lottery.code}/${lottery.id}`) != -1 ? '#e61c00' : '#222' }">{{
                        lottery.name }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div>
        </transition>
      </li>
      <router-link tag="li" to="/lottery/data"
        :class="{ b_red: path.indexOf('/lottery/data') != -1 }"><span>数据</span></router-link>
      <router-link tag="li" to="/lottery/trend"
        :class="{ b_red: path.indexOf('/lottery/trend') != -1 }"><span>走势</span></router-link>
      <router-link tag="li" to="/lottery/lz"
        :class="{ b_red: path.indexOf('/lottery/lz') != -1 }"><span>路珠</span></router-link>
      <!-- <router-link tag="li" to="/lottery/recommend" :class="{b_red: path.indexOf('/lottery/recommend') !=  -1}">推介</router-link> -->
      <!-- <li>推介</li>
      <router-link tag="li" to="/lottery/rj" :class="{b_red: path.indexOf('/lottery/rj') !=  -1}">软件</router-link>
      <li>API</li> -->
      <!-- <li v-if="is_voice" class="right img voice_box" @click="is_audio = !is_audio">
        <img v-if="is_audio" src="@assets/pc/voice.png"/>
        <img v-if="!is_audio" src="@assets/pc/voiceOff.png"/>
      </li> -->
    </ul>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'nn-menu-bar',
  props: {
    fixed: {
      default: false
    }
  },
  components: {
  },
  computed: {
    ...mapGetters('lottery', {
      lotteries: 'list'
    }),
    path() {
      clearTimeout(this.delayMenuT)
      this.delayMenuT = null
      return this.$route.path
    },
    // is_voice () {
    //   const bool = /\/code\//.test(this.path)
    //   return bool
    // }
  },
  watch: {

  },
  data() {
    return {
      showLotterys: false,
      is_audio: true,
      delayMenuT: null
    }
  },
  methods: {
    mouseenter() {
      if (this.delayMenuT !== null) return
      this.showLotterys = true
    },
    mouseleave() {
      if (this.delayMenuT !== null) return
      this.showLotterys = false
    },
    menu(code, id) {
      this.showLotterys = false
      this.delayMenuT = setTimeout(() => {
        clearTimeout(this.delayMenuT)
        this.delayMenuT = null
      }, 200);
      this.$router.push({
        path: `/lottery/code/${code}/${id}`
      })

    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@scss/pc/mixin";
@import "~@scss/pc/variables";

$bar-height: 90px;

.menu-bar {
  height: $bar-height;
  background-color: #fff;
  width: 100%;
  min-width: 1280px;
  left: 0;
  z-index: 1001;
}

.menus {
  list-style: none;
  padding: 0;
  margin-top: 0;
  margin-bottom: 0;
  height: $bar-height;
  line-height: $bar-height;
  color: #282828;
  font-size: 16px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  >li {
    width: 96px;
    text-align: center;
    letter-spacing: 2px;
    cursor: pointer;

    img {
      vertical-align: middle;
    }

    .icon {
      margin-left: 6px;
      height: 5px;
      width: auto;
    }

    &.right {
      float: right;
    }

    &.img {
      padding: 0;
    }

    &:hover {
      font-weight: bold;
      font-size: 18px;
    }

  }
}

@media screen and (max-width: 1700px) {
  .lotterys  {
    .menu_ul {
      padding: 10px 0 6px 30px !important;
    }
    .menu_li {
      margin-bottom: 4px !important;
    }
  }
}

.lotterys {
  position: absolute;
  padding: 10px 0;
  width: 260px;
  background-color: white;
  left: 50%;
  margin-left: -130px;
  box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.1);
  color: black;
  cursor: auto;
  z-index: 9999999;

  .content {
    width: 100%;
  }

  .box {
    width: 100%;
    border-bottom: 1px solid #E3E3E6;

    &:last-child {
      border: none;
    }
  }

  .menu_ul {
    width: 100%;
    list-style: none;
    padding: 20px 0 12px 30px;
    margin: 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    &:hover {
      background-color: #FFF5F5;
    }

    .menu_li {
      margin-bottom: 8px;
      width: 50%;
      font-size: 14px;
      line-height: 24px;
      white-space: nowrap;
      overflow: hidden;
      display: flex;
      align-items: center;

      span {
        cursor: pointer;
      }
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.25s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active below version 2.1.8 */
  {
  // height: 0;
  opacity: 0;
}

.notice_box {
  color: #FFD05E;

  >img {
    margin-left: 12px;
    width: 28px;
    height: auto;
  }
}

.b_red {
  font-weight: bold;
  font-size: 18px;
}

.voice_box {
  width: 50px;
  text-align: center;
}

.logo_box {
  width: auto;
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;

  img {
    margin-top: 9px;
    width: auto;
    height: 72px;
  }
}
</style>
